import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "next-intl";
import { createContext, useContext, useMemo } from "react";

import { ProductActionOutcome } from "~/components/common/product-actions";
import { useAuthContext } from "~/contexts/auth";
import { wishlistIdType, wishlistNameType } from "~/shared/wishlist/wishlist.model";
import { ListProductTrackingInfo } from "~/types/trackings.models";
import Constants from "~/utils/constants";
import TrackingUtils from "~/utils/tracking-utils";

export type CompleteWishlist = EVA.Core.ListUserWishlistsResponse.Wishlist & { Products?: any[]; count: number };

type UpdateWishlistProps = {
  wishlistName: wishlistNameType;
  wishlistId: wishlistIdType;
  onSuccess?: () => void;
  onError?: (error?: Error) => void;
};
type DeleteWishlistProps = {
  wishlistId: wishlistIdType;
  onSuccess?: () => void;
  onError?: (error?: Error) => void;
};

type WishlistContext = {
  list: CompleteWishlist[] | undefined;
  products: any[] | undefined;
  refetch: () => Promise<any>;
  updateWishlist: (data: UpdateWishlistProps) => Promise<EVA.Core.EmptyResponseMessage | undefined>;
  deleteWishlist: (data: DeleteWishlistProps) => Promise<EVA.Core.EmptyResponseMessage | undefined>;
  toggleWishlist: (
    productId: number,
    wishlistId: number,
    updatedList?: CompleteWishlist[],
    trackingData?: ListProductTrackingInfo
  ) => Promise<any>;
  removeProdFromWishlist: (
    productId: number,
    wishlistId: number,
    trackingData?: ListProductTrackingInfo
  ) => Promise<any>;
  addProdToWishlist: (productId: number, wishlistId: number, trackingData?: ListProductTrackingInfo) => Promise<any>;
  saveForLater: (productIDs: number | number[]) => Promise<any>;
  savedForLaterList: CompleteWishlist | undefined;
};

const Context = createContext<WishlistContext | undefined>(undefined);

function _trackWishlistEcomAction(
  action: ProductActionOutcome,
  quantity: number,
  trackingData?: ListProductTrackingInfo
) {
  if (trackingData) {
    if (trackingData.info) {
      trackingData.info.quantity = quantity;
    }

    const trackPayload = TrackingUtils.mapBasketAction(
      "wishlist",
      action,
      trackingData.context ?? "",
      trackingData.info
    );
    trackPayload && TrackingUtils.track(trackPayload);
  }
}

export default function WishlistProvider(props: { children: React.ReactNode }) {
  const t = useTranslations();
  const { user, isAuthenticated } = useAuthContext();

  const evaEndpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);
  const listWishlists = hooks.useCallService({ service: Core.ListUserWishlists });
  const getUserWishlist = hooks.useCallService({ service: Core.GetUserWishlist });
  const deleteWishlistHook = hooks.useCallService({ service: Core.DeleteUserWishlist });
  const updateWishlistHook = hooks.useCallService({ service: Core.UpdateUserWishlist });
  const addProductToWishlist = hooks.useCallService({ service: Core.AddProductsToUserWishlist });
  const removeProductFromWishlist = hooks.useCallService({ service: Core.RemoveProductsFromUserWishlist });
  const createWishlist = hooks.useCallService({ service: Core.CreateUserWishlist });
  const getProductAvailability = hooks.useCallService({ service: Core.GetProductAvailability });

  const { data, refetch } = useQuery({
    queryKey: ["getWishlists", user?.ID],
    staleTime: 0,
    queryFn: async () => {
      const allWishlists = (await listWishlists())?.Results ?? [];
      return await Promise.all(
        allWishlists.map(async ({ ID }) => {
          const wishlist = await getUserWishlist({ ID });
          const products = wishlist?.Products?.filter(Boolean) ?? [];
          if (products.length) {
            const availabilities = await getProductAvailability({
              Products: products.map((p) => ({ ID: p.product_id })),
              Options: { Delivery: { AvailabilityDate: true } },
            });

            for (const product of products) {
              const productAvailability = availabilities?.Products.find((p) => p.ProductID === product.product_id);

              product.is_available = productAvailability?.Delivery?.IsAvailable || false;
              product.has_stock = productAvailability?.Delivery?.HasStock || false;
            }
          }

          return { ...wishlist, Products: products, count: products.length } as CompleteWishlist;
        })
      );
    },
    enabled: !!(evaEndpointUrl && isAuthenticated),
  });

  const filteredList = useMemo(() => {
    if (data) {
      return data.filter((list) => list.Name !== Constants.SAVE_FOR_LATER_WISHLIST_NAME);
    }
  }, [data]);

  const products = useMemo(() => {
    if (filteredList) {
      return filteredList.flatMap((list) => list.Products);
    }
  }, [filteredList]);

  const savedForLaterList = useMemo(() => {
    if (data) {
      return data.find((list) => list.Name === Constants.SAVE_FOR_LATER_WISHLIST_NAME);
    }
  }, [data]);

  const updateWishlist = async (data: UpdateWishlistProps) =>
    await updateWishlistHook(
      {
        ID: data.wishlistId,
        Name: data.wishlistName,
      },
      {},
      {
        onError: async (error) => typeof data.onError === "function" && data.onError(error),
        onSuccess: async () => typeof data.onSuccess === "function" && data.onSuccess(),
      }
    );

  const deleteWishlist = async (data: DeleteWishlistProps) =>
    await deleteWishlistHook(
      {
        ID: data.wishlistId,
      },
      {},
      {
        onError: async (error) => typeof data.onError === "function" && data.onError(error),
        onSuccess: async () => typeof data.onSuccess === "function" && data.onSuccess(),
      }
    );

  const removeProdFromWishlist = async (
    productId: number,
    wishlistId: number,
    trackingData?: ListProductTrackingInfo
  ) => {
    const selectedWishlist = data?.find((list) => list.ID == wishlistId);
    if (selectedWishlist) {
      const alreadyExist = selectedWishlist.Products?.find((prod) => prod.product_id === productId);
      if (alreadyExist) {
        return await removeProductFromWishlist({ ID: wishlistId, ProductIDs: [productId] }).then(() => {
          _trackWishlistEcomAction("removed", 1, trackingData);
          return refetch();
        });
      } else {
        return { errorMessage: "is not in your wishlist" };
      }
    }
  };

  const addProdToWishlist = async (productId: number, wishlistId: number, trackingData?: ListProductTrackingInfo) => {
    const selectedWishlist = data?.find((list) => list.ID == wishlistId);
    if (selectedWishlist) {
      const alreadyExist = selectedWishlist.Products?.find((prod) => prod.product_id === productId);
      if (alreadyExist) {
        return { errorMessage: t("generic.already_in_wishlist") };
      } else {
        return await addProductToWishlist({ ID: wishlistId, ProductIDs: [productId] }).then(() => {
          _trackWishlistEcomAction("added", 1, trackingData);
          return refetch();
        });
      }
    }
  };

  const toggleWishlist = async (
    productId: number,
    wishlistId: number,
    updatedList?: CompleteWishlist[],
    trackingData?: ListProductTrackingInfo
  ) => {
    const currentLists = updatedList ?? filteredList;
    const selectedWishlist = currentLists?.find((list) => list.ID == wishlistId);
    if (selectedWishlist) {
      const alreadyExist = selectedWishlist.Products?.find((prod) => prod.product_id === productId);
      if (alreadyExist) {
        return await removeProductFromWishlist({ ID: wishlistId, ProductIDs: [productId] }).then(() => {
          _trackWishlistEcomAction("removed", 1, trackingData);
          return refetch();
        });
      } else {
        return await addProductToWishlist({ ID: wishlistId, ProductIDs: [productId] }).then(() => {
          _trackWishlistEcomAction("added", 1, trackingData);
          return refetch();
        });
      }
    }
  };

  const saveForLater = async (productIDs: number | number[]) => {
    // Normalize the ProductIDs parameter into an array if it's not already an array
    let arrayedProductIDs = [];
    if (!Array.isArray(productIDs)) {
      arrayedProductIDs = [productIDs];
    } else {
      arrayedProductIDs = [...productIDs?.filter((item) => item !== undefined)];
    }

    // Filter any undefined values from the array (may happen in contexts of OOS products)
    if (!arrayedProductIDs?.length) return undefined;

    if (!savedForLaterList) {
      const response = await createWishlist({
        Name: Constants.SAVE_FOR_LATER_WISHLIST_NAME,
      });
      if (response?.ID) {
        return await addProductToWishlist({ ID: response?.ID, ProductIDs: [...arrayedProductIDs] }).then(() =>
          refetch()
        );
      }
    } else {
      return await addProductToWishlist({ ID: savedForLaterList.ID, ProductIDs: [...arrayedProductIDs] }).then(() =>
        refetch()
      );
    }
  };

  return (
    <Context.Provider
      value={{
        list: filteredList,
        products,
        updateWishlist,
        deleteWishlist,
        refetch,
        toggleWishlist,
        saveForLater,
        addProdToWishlist,
        removeProdFromWishlist,
        savedForLaterList,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useWishlist() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useWishlist must be used within a WishlistProvider`);
  }

  return context;
}
