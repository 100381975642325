import { z } from "zod";

export const quantitySelectorMobileValidationSchema = (productMaxQuantity: number = Infinity) =>
  quantitySelectorDesktopValidationSchema(productMaxQuantity, true).required();

export type QuantitySelectorMobileFields = z.infer<ReturnType<typeof quantitySelectorMobileValidationSchema>>;

export type ChangeQuantityMobileProps = QuantitySelectorMobileFields & {
  maxQuantity: number;
};

export const quantitySelectorDesktopValidationSchema = (productMaxQuantity: number = Infinity, isMobile?: boolean) =>
  z.object({
    Quantity: z
      .number({ invalid_type_error: "error.invalidField", required_error: "error.fieldRequired" })
      .min(0, { message: "error.valueTooLow" })
      .int({ message: "error.valueMustBeInteger" })
      .refine((val) => !isMobile || val <= productMaxQuantity, { message: "error.valueTooHigh" })
      .default(0),
  });

export type QuantitySelectorDesktopFields = z.infer<ReturnType<typeof quantitySelectorDesktopValidationSchema>>;

export type ChangeQuantityDesktopProps = QuantitySelectorDesktopFields & {
  maxQuantity: number;
};
